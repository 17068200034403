import React, { Component, createRef } from 'react';
import emailjs from '@emailjs/browser';
import image from './../../images/aboutus.jpg';
import './../../Styles/fonts.css';
import ReCAPTCHA from 'react-google-recaptcha';
import WhatsAppIcon from './../utils/WhatsAppIcon';
import Footer from './../utils/footer';
import Widget from './../utils/widget';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.form = createRef();
    this.state = {
      name: '',
      email: '',
      phone: '',
      details: '',
      isSending: false,
      recaptchaToken: null
    };
    this.recaptchaRef = createRef();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptchaChange = (token) => {
    this.setState({ recaptchaToken: token });
  };

  sendEmail = (e) => {
    e.preventDefault();
    const { recaptchaToken } = this.state;

    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    this.setState({ isSending: true });

    emailjs.sendForm('service_6atkqq1', 'template_iwrdc4z', this.form.current, '3xl4qSR0n7vnJVA_q')
      .then(
        () => {
          alert('Form submitted successfully');
          this.setState({
            name: '',
            email: '',
            phone: '',
            details: '',
            isSending: false,
            recaptchaToken: null
          });
          this.recaptchaRef.current.reset();
        },
        (error) => {
          console.error('Error submitting form:', error.text);
          alert('An error occurred while submitting the form');
          this.setState({ isSending: false });
        }
      );
  };

  render() {
    const { name, email, phone, details, isSending } = this.state;

    return (
      <div style={{ fontFamily: 'Droid Arabic Kufi' }}>
        <div className="image-container">
          <img
            src={image}
            alt="Description"
            style={{ width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(30%)' }}
          />
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#F9FAFC',
            fontSize: 'calc(1.5vw + 1.5vh)',
            fontWeight: 'bold',
            textAlign: 'center',
            fontFamily: 'Droid Arabic Kufi'
          }}>
            <h5 style={{ fontSize: 'calc(2vw + 2vh)' }}>لا تتردد في التواصل معنا</h5>
          </div>
        </div>
        <div className="widgets-container">
          <Widget
            title="المكان"
            content="العراق - بغداد"
          />
          <Widget
            title="البريد الالكتروني"
            content="info@partners-solutions.iq"
          />
          <Widget
            title="رقم الهاتف"
            content="+9647747999974"
          />
        </div>
        <div className="form-container">
          <h2>نموذج تواصل معنا</h2>
          <hr />
          <form ref={this.form} onSubmit={this.sendEmail}>
            <label>
              الاسم
              <input type="text" name="name" value={name} onChange={this.handleChange} required />
            </label>
            <label>
              البريد الالكتروني
              <input type="email" name="email" value={email} onChange={this.handleChange} required />
            </label>
            <label>
              الهاتف
              <input type="tel" name="phone" value={phone} onChange={this.handleChange} required />
            </label>
            <label>
              التفاصيل
              <textarea name="details" value={details} onChange={this.handleChange} required></textarea>
            </label>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey="6LfiOxcqAAAAAHUHajy1Pvvpk50poQNWBwzl_gz4"
                onChange={this.handleRecaptchaChange}
              />
            </div>
            <button type="submit" disabled={isSending}>
              {isSending ? '...إرسال' : 'إرسال'}
            </button>
          </form>
        </div>
        <WhatsAppIcon />
        <Footer />
      </div>
    );
  }
}

export default Contact;