import React, { Component } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import './../../Styles/Sidebar.css';

class Sidebar extends Component {
  render() {
    return (
      <div className={classnames('sidebar', { 'sidebar-open': this.props.isOpen })}>
        <div className="sidebar-content">
          <ul style={{ textAlign: 'right', fontFamily: 'Droid Arabic Kufi' }}>
            <li><Link to="/" onClick={this.props.toggleSidebar}>الصفحة الرئيسية</Link></li>
            <li><Link to="/about" onClick={this.props.toggleSidebar}>من نحن</Link></li>
            <li><Link to="/contact" onClick={this.props.toggleSidebar}>اتصل بنا</Link></li>
            <li><Link to="/services" onClick={this.props.toggleSidebar}>الخدمات</Link></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;