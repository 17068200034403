import React, { Component } from 'react';
import { withRouter } from '../utils/withRouter';
import { Link } from 'react-router-dom';
import image from './../../images/fullSolutionPartners.png';
import './../../Styles/Navbar.css';

class Navbar extends Component {
    state = {
        isOpened: false,
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ isOpened: false });
        }
    }

    handleToggleSidebar = () => {
        this.props.toggleSidebar();
        this.setState(prevState => ({
            isOpened: !prevState.isOpened
        }));
    }

    render() {
        const icon = this.state.isOpened ? '✕' : '☰';

        return (
            <nav className="navbar" style={{ fontFamily: 'Droid Arabic Kufi' }}>
                <div className="burger-menu" onClick={this.handleToggleSidebar}>
                    {icon}
                </div>
                <ul className="nav-items">
                    <li><Link to="/services" onClick={this.props.toggleSidebar}>الخدمات</Link></li>
                    <li><Link to="/about" onClick={this.props.toggleSidebar}>من نحن</Link></li>
                    <li><Link to="/contact" onClick={this.props.toggleSidebar}>اتصل بنا</Link></li>
                    <li><Link to="/" onClick={this.props.toggleSidebar}>الصفحة الرئيسية</Link></li>
                </ul>
                <div className="navbar-brand">
                    <img
                        src={image}
                        alt="Description"
                        className="navbar-image"
                    />
                </div>

            </nav>
        );
    }
}

export default withRouter(Navbar);