import React from 'react';
import './../../Styles/fonts.css';

const Widget = ({ title, content }) => {
  return (
    <div style={widgetStyle}>
      <h2 style={widgetTitleStyle}>{title}</h2>
      {Array.isArray(content) ? (
        <ul style={widgetContentListStyle}>
          {content.map((item, index) => (
            <li key={index} style={widgetContentItemStyle}>
              <span>{item}</span> <span style={bulletStyle}>•</span>
            </li>
          ))}
        </ul>
      ) : (
        <p style={widgetContentStyle}>{content}</p>
      )}
    </div>
  );
};

const widgetStyle = {
  background: 'white',
  padding: '20px',
  margin: '20px',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  maxWidth: '600px',
  textAlign: 'center',
};

const widgetTitleStyle = {
  fontFamily: 'Droid Arabic Kufi',
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '10px',
};

const widgetContentListStyle = {
  fontFamily: 'Droid Arabic Kufi',
  fontSize: '16px',
  color: '#555',
  textAlign: 'right',
  listStyleType: 'none',
  padding: 0,
};

const widgetContentItemStyle = {
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

const widgetContentStyle = {
  fontFamily: 'Droid Arabic Kufi',
  fontSize: '16px',
  color: '#555',
};

const bulletStyle = {
  marginRight: '5px',
  marginLeft: '10px'
};

export default Widget;