import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import image from './../../images/photo-1520607162513-77705c0f0d4a.jpeg';
import './../../Styles/fonts.css';
import Widget from './../utils/widget';
import WhatsAppIcon from './../utils/WhatsAppIcon';
import Footer from './../utils/footer';

const Home = () => {
  const servicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/services') {
      servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div>
      <div style={{ position: 'relative', width: '100%', height: '700px', zIndex: 1 }}>
        <img
          src={image}
          alt="Description"
          style={{ width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(50%)' }}
        />
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#F9FAFC',
          fontSize: 'calc(1.5vw + 1.5vh)',
          fontWeight: 'bold',
          textAlign: 'center',
          fontFamily: 'Droid Arabic Kufi'
        }}>
          <h5 style={{ fontSize: 'calc(2vw + 2vh)' }}>Partners Solutions</h5>
          <h5 style={{ fontSize: 'calc(2vw + 2vh)', marginTop: '-10%' }}>للتسويق والخدمات الادارية والتجارة الالكترونية</h5>
          <p style={{ fontSize: 'calc(1vw + 1vh)', marginTop: '-5%', marginLeft: '-20%', marginRight: '-20%' }}>
            عميلنا العزيز مكتبنا الإلكتروني يسعى دائما وابدًا للوصول بكم إلى أولى خطوات النجاح وتحقيقها بكل دقة مع خالص الود
          </p>
        </div>
      </div>
      <div className="widgets-container">
        <Widget
          title="رؤيتنا"
          content="أن نكون أحد المساهمين في النهوض بالاقتصاد العراقي بما في ذلك القطاع الخاص والقطاع الحكومي والشركات الأجنبية بما يخدم توفير فرص العمل في السوق العراقية"
        />
        <Widget
          title="رسالتنا"
          content="تقديم أفضل الخدمات التي تناسب احتياجات عملائنا وبأعلى مستوى من المعايير المطابقة للأنظمة العراقية والدولية"
        />
        <Widget
          title="أهدافنا"
          content={[
            "تحقيق الاستمرارية والالتزام المهني في كافة خدماتنا",
            "تنمية القدرات الوطنية اللازمة في كافة القطاعات",
            "تحقيق أفضل القيم للعملاء وضمان رضاهم"
          ]}
        />
      </div>
      <div
        ref={servicesRef}
        style={{
          transform: 'translate(-50%, -50%)',
          color: 'black',
          fontSize: '30px',
          fontWeight: 'bold',
          textAlign: 'center',
          fontFamily: 'Droid Arabic Kufi',
          marginLeft: '50%',
          marginBottom: '-3%',
          marginTop: '100px'
        }}>
        <h5 style={{ color: '#3c6d71', fontSize: 'calc(2vw + 2vh)' }}>خدماتنا</h5>
      </div>
      <div className="widgets-container">
        <Widget
          title="البناء المؤسسي والخدمات الإدارية"
          content={[
            "التخطيط الاستراتيجي وبناء مؤشرات الأداء",
            "دراسة وبناء الهياكل التنظيمية",
            "إعداد سياسات و إجراءات الشاملة",
            "إدارة المنشآت (الإشراف الإداري)",
            "تطوير استراتيجيات الحَوْكمة و وضع استراتيجيات وإجراءات وسياسات تتماشى مع أهداف المؤسسة"
          ]}
        />
        <Widget
          title="إداره الأصول"
          content={[
            "إعداد دراسات للسياسات والخطط المالية",
            "القيام بدراسة وتقييم للوضع المالي واعداد واقتراح بدائل وحلول لتطوير نشاط عمل الشركة وفقاً للمعطيات الاستراتيجية",
            "تحليل واعداد انظمة للجوانب المالية والمحاسبية",
            "إعداد سياسات وإجراءات الميزانيات والرقابة الداخلية",
            "إعداد لوائح و إجراءات التقارير المالية ومحاسبة التكاليف",
            "إعداد سياسات و إجراءات المشتريات والعقود والمستودعات",
            "القيام بالمراجعة والتحليل المالي لوضع الشركة وتقديم تقرير خاص",
            "اعداد دراسات لتصميم النظام المحاسبي و المالي",
            "اعداد الاجراءات والرقابة المالية للاصول و المخزون",
            "اعداد دراسات الجدوى الداخلية للمؤسسة",
            "مراجعة وتدقيق القوائم المالية السنوية"
          ]}
        />
        <Widget
          title="خدمات الموارد البشرية"
          content={[
            "تصميم الهيكل التنظيمي للمنشأة",
            "تصميم الوصف الوظيفي ودليل الأداء لكل وظيفة",
            "الاستقطاب والإختيار والتعيين",
            "تحليل الإحتياجات التدريبية",
            "تصميم البرامج التدريبية وتنفيذها",
            "لائحة وهيكلة الرواتب والأجور"
          ]}
        />
      </div>
      <div className="widgets-container">
        <Widget
          title="خدمات التسويق"
          content={[
            "إجراء البحوث والدراسات السوقية اللازمة للمنتجات",
            "تصميم وتنفيذ الحملات التسويقية سواء التقليدية أو التسويق الإلكتروني",
            "رفع كفاءة فريق التسويق والمبيعات داخل المنشأة وتطويره",
            "تصميم المواقع الالكترونية بأحترافية عالية"
          ]}
        />
        <Widget
          title="خدمات الاستشارات التقنية"
          content={[
            "اللازم للمنشأة بما يتناسب مع طبيعة وحجم النشاط ERP تقييم واختبار نظام",
            "التدقيق الفني والأمني  للانظمة وتقييم إجراءات الأمان والحماية المتبعة من قبل الشركات المزودة"
          ]}
        />
        <Widget
          title="خدمات التأهيل والتدريب"
          content={[
            "التأهيل والتدريب محاسبة و مالية",
            "التأهيل والتدريب مبيعات وتسويق",
            "التأهيل والتدريب الموارد البشرية"
          ]}
        />
      </div>
      <br /><br /><br /><br /><br /><br />
      <WhatsAppIcon />
      <Footer />
    </div>
  );
}

export default Home;