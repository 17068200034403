import React, { Component } from 'react';
import image from './../../images/aboutus.jpg';
import './../../Styles/fonts.css';
import WhatsAppIcon from './../utils/WhatsAppIcon';
import Footer from './../utils/footer';

class About extends Component {
  render() {
    return (
      <div style={{fontFamily: 'Droid Arabic Kufi'}}>
        <div className="image-container">
          <img
            src={image}
            alt="Description"
            style={{ width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(30%)' }}
          />
        </div>
        <div className="content-container">
          <h1>المكتب الالكتروني</h1>
          <hr />
          <p> حلول الشركاء هو مكتب خدمات ادارية و تسويقية تأسس في العراق ومقره في بغداد، يحرص على تقديم الخدمات لكافة القطاعات الاستثمارية في العراق.
ان كادرحلول الشركاء كادر مهني ذو تجربة كبيرة ضمن الاختصاص. خبرات كادرنا نتاج سنوات طويلة من الخبرة العلمية و العملية في الشركات المحلية و الاجنبية بشكل  مما يساهم بشكل فعال في تقديم الخدمات فريدة لعملائها.
</p>
          <br /><br /><br /><br /><br />
        </div>
        <WhatsAppIcon />
        <Footer />
      </div>
    );
  }
}

export default About;