import React from 'react';
import './../../Styles/fonts.css';
import './../../Styles/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h2>من نحن</h2>
          <p>حلول الشركاء هو مكتب خدمات ادارية و تسويقية تأسس في العراق ومقره في بغداد، يحرص على تقديم الخدمات لكافة القطاعات الاستثمارية في العراق</p>
        </div>
        <div className="footer-section">
          <h2>اتصل بنا</h2>
          <p>الهاتف: 9647747999974</p>
          <p>info@partners-solutions.iq :البريد الالكتروني</p>
        </div>
        <div className="footer-section">
        <h2>تابعنا</h2>
          <a
            href="https://www.linkedin.com/company/partnerssolutions/"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-icon"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {currentYear} جميع الحقوق محفوظة لمكتب حلول الشركاء</p>
      </div>
    </div>
  );
};

export default Footer;